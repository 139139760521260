import { postDigiclueplus } from 'api';
import PageTitle from 'component/PageTitle';
import MainLayout from 'layout/MainLayout';
import { openWindow } from 'lib';
import { FC } from 'react';
import { Button, Col, Row, Stack, Image } from 'react-bootstrap';
import { useAsyncFn } from 'react-use';

import pointCouponExplanationImg from 'assets/image/point_coupon_explanation.png';

const DigiclueplusPointCoupons: FC = () => {
  PageTitle('クーポン');
  const [{ loading }, onClickNextButton] = useAsyncFn(
    // 「次へ」ボタン時の処理
    async () => {
      const res = await postDigiclueplus('point_coupons');
      if (!res || res.status === 'failed')
        return window.location.replace(process.env.PUBLIC_URL + '/error/500');
      openWindow(res.data.service_url, false);
    },
    []
  );

  return (
    <MainLayout footer>
      <Row className="pt-4">
        <Col md={7} className="mx-auto">
          <Stack gap={2}>
            <Image
              src={pointCouponExplanationImg}
              alt="ポイントクーポン説明画像"
              fluid
            />
            <p className="mb-2 text--md p-0">
              【 注意事項をご確認の上、ご利用ください】
            </p>
            {/* 注意事項 */}
            <Stack className="mb-3" gap={1}>
              <p className="mb-0 text--md">
                ●対象商品をご購入の際は、
                <span className="text--md text--primary fw-bold">
                  マキヤアプリホーム画面バーコード、又は、プリカカードを提示
                </span>
                してください。お忘れになった場合、後からの付与はできません。
              </p>
              <p className="mb-0 text--md">
                ●
                <span className="text--md text--primary fw-bold">
                  電子マネー
                </span>
                はお買上げ日の
                <span className="text--md text--primary fw-bold">
                  翌月中旬頃
                </span>
                を予定しております。付与後「お知らせ」を配信いたします。
              </p>
              <p className="mb-0 text--md">
                ●一部店舗では商品の取扱いがない場合がございます。また、万が一品切れ、及び、入荷のない場合はご容赦ください。
              </p>
            </Stack>

            <Stack className="mb-2" gap={2}>
              <Button
                variant="-primary"
                className="py-3 btn--custom"
                disabled={loading}
                onClick={onClickNextButton}
              >
                クーポンを見る
              </Button>
              <Button
                variant="link"
                className="text--md text--dark text-center text-decoration-underline p-0"
                onClick={() =>
                  openWindow(
                    'https://www.makiya-group.co.jp/espot/event/161.html'
                  )
                }
              >
                クーポンの詳しい使い方はこちら
              </Button>
            </Stack>
          </Stack>
        </Col>
      </Row>
    </MainLayout>
  );
};

export default DigiclueplusPointCoupons;
