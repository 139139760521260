import React, { useEffect, useState } from 'react';
import { Row, Col, Stack } from 'react-bootstrap';

import { getCurrentUser } from 'api';
import { API_SUCCEEDED } from 'lib/constants';

import MainLayout from 'layout/MainLayout';
import PageTitle from 'component/PageTitle';
import { MenuGroup, MenuItem } from 'component/MenuGroup';

import MagnifyIcon from 'assets/image/icons/magnify.svg';
import IdIcon from 'assets/image/icons/id.svg';
import ExternalIcon from 'assets/image/icons/external.svg';
import CardIcon from 'assets/image/icons/card.svg';
import ChevronRightIcon from 'assets/image/icons/chevron_right.svg';
import PreOrderIcon from 'assets/image/icons/pre_order_except_text.svg';
import ContactIcon from 'assets/image/icons/contact.svg';
import { PRODUCT_RESERVATION_LINK } from 'lib/constants';

const MyPage: React.FC = () => {
  PageTitle('マイページ');
  const [isDone, setIsDone] = useState<boolean>(false);

  useEffect(() => {
    getCurrentUser().then((res) => {
      if (res.status === API_SUCCEEDED) setIsDone(true);
    });
  }, []);

  return (
    <MainLayout footer>
      <Row className="row--no-mt">
        <Col md={7} className="mx-auto">
          {isDone && (
            <MenuGroup>
              <MenuItem link="search">
                <div>
                  <img
                    src={MagnifyIcon}
                    alt=""
                    className="me-2 menu-item-icon"
                  />
                  店舗検索
                </div>
                <img src={ChevronRightIcon} alt="" />
              </MenuItem>
              <MenuItem link="account">
                <div>
                  <img src={IdIcon} alt="" className="me-2 menu-item-icon" />
                  会員情報
                </div>
                <img src={ChevronRightIcon} alt="" />
              </MenuItem>
              <MenuItem link={PRODUCT_RESERVATION_LINK} external>
                <Stack
                  direction="horizontal"
                  className="justify-content-between"
                >
                  <img
                    src={PreOrderIcon}
                    alt="商品予約"
                    className="me-2 menu-item-icon"
                  />
                  商品予約
                </Stack>
                <img src={ChevronRightIcon} alt="" />
              </MenuItem>
              <MenuItem link="reissue">
                <div>
                  <img src={CardIcon} alt="" className="me-2 menu-item-icon" />
                  カード紛失時または 再発行後のお手続き
                </div>
                <img src={ChevronRightIcon} alt="" />
              </MenuItem>
              <MenuItem
                link="https://www.makiya-group.co.jp/contact/index.php"
                external
              >
                <Stack
                  direction="horizontal"
                  className="justify-content-between"
                >
                  <img
                    src={ContactIcon}
                    alt="お問い合わせ"
                    className="me-2 menu-item-icon"
                  />
                  お問い合わせ
                </Stack>
                <img src={ChevronRightIcon} alt="" />
              </MenuItem>
              <MenuItem
                link="https://www.makiya-group.co.jp/espot/event/15896.html"
                external
              >
                <Stack direction="horizontal" className="align-items-center">
                  ポイントから電子マネーへの交換方法
                  <img src={ExternalIcon} alt="" className="ms-2" />
                </Stack>
                <img src={ChevronRightIcon} alt="" />
              </MenuItem>
              <MenuItem
                link="https://www.makiya-group.co.jp/espot/event/13841.html"
                external
              >
                <Stack direction="horizontal" className="align-items-center">
                  クレジットカードチャージの方法
                  <img src={ExternalIcon} alt="" className="ms-2" />
                </Stack>
                <img src={ChevronRightIcon} alt="" />
              </MenuItem>
              <MenuItem
                link="https://www.makiya-group.co.jp/espot/event/8779.html"
                external
              >
                <Stack direction="horizontal" className="align-items-center">
                  スマートレシートのご利用方法
                  <img src={ExternalIcon} alt="" className="ms-2" />
                </Stack>
                <img src={ChevronRightIcon} alt="" />
              </MenuItem>
              <MenuItem
                link="https://www.makiya-group.co.jp/espot/event/10779.html"
                external
              >
                <Stack direction="horizontal" className="align-items-center">
                  ホーム画面にショートカットを追加
                  <img src={ExternalIcon} alt="" className="ms-2" />
                </Stack>
                <img src={ChevronRightIcon} alt="" />
              </MenuItem>
              <MenuItem
                link="https://www.makiya-group.co.jp/espot/event/6629.html"
                external
              >
                <Stack direction="horizontal" className="align-items-center">
                  マキヤLINEミニアプリ利用規約
                  <img src={ExternalIcon} alt="" className="ms-2" />
                </Stack>
                <img src={ChevronRightIcon} alt="" />
              </MenuItem>
              <MenuItem
                link="https://www.makiya-group.co.jp/espot/news/terms.html"
                external
              >
                <Stack direction="horizontal" className="align-items-center">
                  マキヤプリカ利用規約
                  <img src={ExternalIcon} alt="" className="ms-2" />
                </Stack>
                <img src={ChevronRightIcon} alt="" />
              </MenuItem>
              <MenuItem
                link="https://www.makiya-group.co.jp/privacy.html"
                external
              >
                <Stack direction="horizontal" className="align-items-center">
                  プライバシーポリシー
                  <img src={ExternalIcon} alt="" className="ms-2" />
                </Stack>
                <img src={ChevronRightIcon} alt="" />
              </MenuItem>
            </MenuGroup>
          )}
        </Col>
      </Row>
    </MainLayout>
  );
};
export default MyPage;
