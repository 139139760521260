import React, { useRef, useState } from 'react';
import { Row, Stack, Button } from 'react-bootstrap';

import FullHeightLayout from 'layout/FullHeightLayout';
import PageTitle from 'component/PageTitle';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useAsyncFn } from 'react-use';
import { patchPrepaidCharge } from 'api';
import { API_FAILED, API_SUCCEEDED } from 'lib/constants';
import Loader from 'component/Loader';
import { errorCodes } from 'lib/errorCodes';
import usePrepaidBalanceTotal from 'lib/hooks/usePrepaidBalanceTotal';

const PointDisplay: React.FC<{
  title: string;
  pointValue?: string;
  pointDisplay?: boolean;
}> = ({ title, pointValue, pointDisplay }) => {
  if (typeof pointValue === 'undefined') return <></>;

  return (
    <Stack gap={3}>
      <p className="text--md text--secondary-light mb-0">{title}</p>
      <p className="mb-0">
        <span className="text--lg mx-3">{pointValue}</span>
        <span className="text--md">{pointDisplay ? 'ポイント' : '円'}</span>
      </p>
    </Stack>
  );
};

/** プリカチャージ ポイントチャージ確認画面 */
const PrepaidChargeConfirm: React.FC = () => {
  PageTitle('ポイントチャージ');

  const navigate = useNavigate();
  const { state, pathname } = useLocation();

  // NOTE: 別のページへ遷移する際にstateが消えて確認画面の表示が崩れるので、useRefを利用
  const points = useRef({
    totalPoint: state?.totalPoint,
    changePoint: state?.changePoint
  });
  // 確認画面から別ページに遷移したことがないかの状態フラグ
  const [isNotNavigated, setIsNotNavigated] = useState(true);

  const { prepaidBalanceTotal } = usePrepaidBalanceTotal(true);

  const [{ loading: isMutating }, onClickExchangeButton] =
    useAsyncFn(async () => {
      const res = await patchPrepaidCharge(Number(state?.changePoint ?? 0));
      const apiFailFlag = res && res?.status === API_FAILED;

      setIsNotNavigated(false);
      // API実行が成功した場合、完了ページへ遷移
      if (res && res.status === API_SUCCEEDED) {
        navigate(pathname, { replace: true }); // stateを削除
        navigate('/prepaid-charge/complete');
        return;
      }

      // 400の場合は、入力したポイントに関するエラーなので、入力画面へ戻る
      if (apiFailFlag && res.http_status === 400) {
        navigate('/prepaid-charge', {
          state: {
            ...state,
            // NOTE: 400でエラーコードが存在すケースは、残高不足エラーのみなので、
            // エラーコードがない場合はエラーメッセージをそのまま設定する
            errorMessage:
              res.error_code === '031' || res.error_code === '033'
                ? errorCodes[res.error_code].desc
                : res?.errors[0],
            errorCode: res.error_code
          }
        });
        return;
      }

      // サービス時間外のエラーが返却された場合、入力画面に戻る
      // NOTE: サービス時間外の場合、夜間アクセス制御に伴う特定URLアクセスでのエラー制御により、
      // http statusを含むresponseが正常に受け取れないので、axios処理からfalseが返却されることを確認する
      if (!res) {
        // NOTE: この場合、プリペイド残高取得APIもエラーするのでエラーメッセージは設定しない
        navigate('/prepaid-charge', { state });
        return;
      }

      // VDマネーの加算処理が失敗したケースのエラーコードが返却された場合は、完了画面で失敗表示
      if (
        apiFailFlag &&
        (res.error_code === '035' || res.error_code === '036')
      ) {
        navigate(`/prepaid-charge/complete?fail=true&code=${res.error_code}`);
        return;
      }

      // タイムアウトエラーが返却された場合は、完了画面で失敗表示
      if (apiFailFlag && res.error_code === '037') {
        navigate(`/prepaid-charge/complete?fail=true&code=${res.error_code}`);
        return;
      }

      // その他エラーを表すエラーコードの場合、入力画面でエラー表示
      if (apiFailFlag && res.error_code === '034') {
        navigate('/prepaid-charge', {
          state: {
            ...state,
            errorMessage: errorCodes[res.error_code].desc,
            errorCode: res.error_code
          }
        });
        return;
      }

      // アプリ共通の想定外エラー
      navigate('/prepaid-charge/complete?fail=true&code=unexpected');
    }, [state]);

  // state が 0 もしくは undefined や null かつ 確認画面から別のページへ遷移していない場合は入力画面へ戻る
  if (!state?.changePoint && isNotNavigated) {
    return <Navigate to="/prepaid-charge" />;
  }

  return (
    <>
      <FullHeightLayout white>
        <p className="text--lg text--secondary fw-bold mb-5">
          ポイントをマキヤプリカへチャージします。
          <br />
          この操作は取り消せません、よろしいですか？
        </p>
        <Row>
          <Stack gap={4}>
            <PointDisplay
              title="保有ポイント数"
              pointValue={points.current.totalPoint}
              pointDisplay
            />
            <PointDisplay
              title="チャージポイント数"
              pointValue={points.current.changePoint}
              pointDisplay
            />
            <PointDisplay
              title="チャージ後のマキヤプリカ"
              pointValue={String(
                (typeof prepaidBalanceTotal !== 'undefined'
                  ? prepaidBalanceTotal
                  : 0) + points.current.changePoint
              )}
            />
            <Stack gap={2}>
              <p className="text--secondary-light text--md m-0">
                マキヤプリカはボーナス残高にチャージされます
              </p>
              <p className="text--secondary-light text--md m-0">
                マキヤプリカ残高からポイントへ戻すことはできません
              </p>
            </Stack>
          </Stack>
        </Row>
        <Row className="bottom-fix mb-3">
          <Stack gap={3}>
            <Button
              className="btn py-3 btn--custom btn--primary"
              onClick={onClickExchangeButton}
              disabled={isMutating}
            >
              {isMutating ? <Loader /> : 'チャージする'}
            </Button>
            <Button
              className="btn py-3 btn--custom btn--primary-outline bg-white"
              onClick={() => {
                navigate('/prepaid-charge', { state });
              }}
              disabled={isMutating}
            >
              {isMutating ? <Loader /> : '戻る'}
            </Button>
          </Stack>
        </Row>
      </FullHeightLayout>
    </>
  );
};
export default PrepaidChargeConfirm;
